<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o022">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">판매자격연수 현황</h3>
          </header>
          <div class="popup-content">
            <!-- table-list-container -->
            <div class="kb-table-list-container">
              <!-- list-top -->
              <div class="list-top">
                <div class="top-column">
                </div>
                <div class="top-column">
<!--                  <strong class="total">총 {{ targetAbilities.length }}명</strong>-->
<!--                  <a href="javascript:" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadExcel"><i class="icon-download"></i><span class="text">엑셀다운로드</span></a>-->
                </div>
              </div>
              <nav class="list-tabs">
                <ul class="tab-list">
                  <li v-for="(tab, index) in view.tabs" class="tab-item" :class="{'is-active': view.current === tab.key}" :key="index">
                    <a href="javascript:" class="tab" @click="view.current = tab.key">{{ tab.name }}</a>
                  </li>
                </ul>
              </nav>

              <div class="kb-table kb-table-padding kb-table-border">
                <table>
                  <colgroup>
                    <col>
                    <col>
                    <col>
                    <col>
                    <col v-if="view.current === '2066015'">
                    <col v-if="view.current !== '2066015'">
                    <col v-if="view.current !== '2066015'">
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="cell-border"><span class="th-title">과정차수명</span></th>
                    <th class="cell-border"><span class="th-title">학습기간</span></th>
                    <th v-if="view.current === '2066015'" class="cell-border"><span class="th-title">구분</span></th>
                    <th class="cell-border"><span class="th-title">콘텐츠명</span></th>
                    <th class="cell-border"><span class="th-title">{{view.current === '2066015' ? '학습시간' : '누적 진도율'}}</span></th>
                    <th v-if="view.current !== '2066015'" class="cell-border"><span class="th-title">콜드콜</span></th>
                    <th v-if="view.current !== '2066015'" class="cell-border"><span class="th-title">학습완료여부</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item, index) in renderItems" :key="index">
                    <tr v-if="item.learns && item.learns.length > 0">
                      <td :rowspan="item.learns.length" class="padding-12"><span class="td-text">{{item.crseNm}}</span></td>
                      <td :rowspan="item.learns.length" class="padding-12"><span class="td-text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                      <td v-if="view.current === '2066015'" class="padding-12"><span class="td-text">{{item.learns[0].chapNm ? item.learns[0].chapNm: '-'}}</span></td>
                      <td class="padding-12"><span class="td-text">{{item.learns[0].objNm}}</span></td>
                      <td class="padding-12"><span class="td-text" v-html="getScore(item.learns[0], view.current)"></span></td>
                      <td v-if="view.current !== '2066015'" class="padding-12">
                        <span class="td-text" v-html="getColdCall(item.learns[0], item.lrnTrgtGrpDtlSn)"></span>
                      </td>
                      <td v-if="view.current !== '2066015'" class="padding-12">
                        <span class="td-text" v-html="getFinishText(item, item.learns[0], view.current)"></span>
                      </td>
                    </tr>
                    <template v-for="(learn, idx) in item.learns" :key="`${index}-${idx}`">
                      <tr v-if="idx > 0">
                        <td v-if="view.current === '2066015'" class="padding-12"><span class="td-text">{{learn.chapNm ? learn.chapNm : '-'}}</span></td>
                        <td class="padding-12"><span class="td-text">{{learn.objNm}}</span></td>
                        <td class="padding-12"><span class="td-text" v-html="getScore(learn, view.current)"></span></td>
                        <td v-if="view.current !== '2066015'" class="padding-12">
                          <span class="td-text" v-html="getColdCall(learn, item.lrnTrgtGrpDtlSn)"></span>
                        </td>
                        <td v-if="view.current !== '2066015'" class="padding-12">
                          <span class="td-text" v-html="getFinishText(item, learn, view.current)"></span>
                        </td>
                      </tr>
                    </template>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>
<script>

import {learnRegularStatusSetup} from '@/assets/js/modules/learn/learn-regular-status-setup';

export default {
  name: "LearnRegularStatus",
  components: {},
  props: {
    toggle: Boolean,
    items: Array
  },
  emits: ['update:toggle'],
  setup: learnRegularStatusSetup
}
</script>
