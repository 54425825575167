import {ref} from 'vue';
import {isSuccess, numberComma, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_GET_CRSE_DIST_REGULAR_LIST,
  ACT_INSERT_CRSE_DIST_REGULAR,
} from '@/store/modules/course/course';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import {getListFunc} from '@/assets/js/ui.init';
import {
  sortCourseItems,
  sortKeyDesc,
} from '@/assets/js/modules/learn/learn-regular-sort';

export const trainRegularSetup = () => {
  const {showLoading, hideLoading} = useAlert();
  const currentYear = new Date().getFullYear();
  const router = useRouter();
  const isReady = ref(false);
  const statusToggle = ref(false);
  const items = ref([]);
  const categories = [
    {title: '직무수행교육', splCrseTyCdDcd: '2066013'},
    {title: '방카슈랑스 판매자격', splCrseTyCdDcd: '2066015'},
    {title: '상품별 판매자격', splCrseTyCdDcd: '2066014'},
    {title: 'AFPK/CFP 윤리교육', splCrseTyCdDcd: '2066019'},
  ];

  const renderItems = ref([]);
  const statuses = ref([]);
  const goFcpl = () => {
    router.push({name: 'FcplMain'});
  }
  const showStatus = () => {
    showLoading();
    getListFunc(`course/${ACT_GET_CRSE_DIST_REGULAR_LIST}`, {statusYn: 'Y', bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, statuses, null, () => {
      hideLoading();
      statusToggle.value = true;
    });
  }
  showLoading();

  getListFunc(`course/${ACT_GET_CRSE_DIST_REGULAR_LIST}`, {bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, items, null, () => {
    // const distinctItems = distinctItems(items.value);
    renderItems.value = categories.map(x => (
        {
          ...x,
          items: x.splCrseTyCdDcd === '2066013' ? sortKeyDesc(sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd)), 'bgngDt') : sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd))
        }
    ));

    hideLoading();
    isReady.value = true;
  });

  return {
    isReady,
    statusToggle,
    items,
    statuses,
    renderItems,
    showStatus,
    goFcpl
  }
}

export const trainRegularCourseSetup = () => {
  const store = useStore();
  const router = useRouter();
  const {showConfirm, showMessage} = useAlert();

  const isLoading = ref(false);
  const enrollCourse = (item) => {
    if(isLoading.value) return;
    isLoading.value = true;

    store.dispatch(`course/${ACT_INSERT_CRSE_DIST_REGULAR}`, item.distCrseSn).then(res => {
      if(isSuccess(res)){
        router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
      }else{
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      isLoading.value = false;
    })
  }

  const goLearn = (item) => {
    if(item.lrnTrgtGrpDtlSn > 0){
      router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
    }else{
      showConfirm({
        title: item.crseNm,
        text : '해당 연수에 입과하시겠습니까?',
        callback: () => {enrollCourse(item);}
      });
    }
  }




  const standardMinute = (60 * 40);

  const getFinishText = (item) => {
    // 입과 하였을 경우
    if(item.lrnTrgtGrpDtlSn > 0 && item.learns.length > 0){
      if(item.splCrseTyCdDcd === '2066015'){
        // 변액보험 판매자격의 경우 콜드콜 여부를 사용하지 않는다.
        return item.learns.filter(x => x.accumPlaySec >= standardMinute).length === item.learns.length ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>'
      }else{
        // 콜드콜을 사용하는 경우...
        return item.fnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
        // if (item.coldCallUseYn === 'Y') {
        //   return item.learns.filter(x => x.totProgRate >= 95 && (x.maxPlaySec < 60 || x.coldCallRspnsDt > 0)).length === item.learns.length ?  '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
        // }
        // return item.learns.filter(x => x.totProgRate >= 95).length === item.learns.length ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
      }
    }
    return '<span class="text-muted">미완료</span>';
  }

  return {
    goLearn,
    timestampToDateFormat,
    numberComma,
    getThumbUrl,
    getFinishText
  }

}
